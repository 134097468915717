.auth-container {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}
.auth-container h2{ padding-bottom: 15px;     text-align: center;}
.auth-form {
  display: flex;
  flex-direction: column;
}

.auth-form input {
  margin-bottom: 10px;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.auth-form button {
  padding: 10px 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 30%;
  margin: 10px auto;
}

.auth-form button:hover {
  background-color: #0056b3;
}

p {
  margin-top: 10px;
  text-align: center;
}

p a {
  color: #007bff;
  text-decoration: none;
}

p a:hover {
  text-decoration: underline;
}
.error{ color: #ff0000; margin-bottom: 10px;}