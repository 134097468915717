* {
  padding: 0;
  margin: 0;
}

.App {
  width: 70%;

  width: 50%;
  margin-left: 340px;
  margin-top: 35px;
}

.App input {
  width: 80%;
  margin: 8px 8px;
  height: 2rem;
  padding-left: 20px;
  font-size: larger;
}

.App button {
  margin: 8px 8px;
}

.postmain {
  border: 1px solid #ccc;
  border-radius: 8px;
  margin: 16px;
  padding: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  width: 20%;
}

.post-image {
  width: 100%;
  height: auto;
  border-radius: 8px 8px 0 0;
}

.post-content {
  padding: 16px;
}

.post-content h3 {
  margin-top: 0;
}

.post-content h4 {
  color: #555;
}

.post-content p {
  margin-bottom: 16px;
}

.read-more-btn {
  background-color: #3498db;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.read-more-btn:hover {
  background-color: #2980b9;
}
.postlist {
  display: flex;
  flex-wrap: wrap;
}
#h2 {
  text-align: center;
  margin-top: 40px;
}
.read-more-btn {
  margin-top: 10px;
}

.main {
  display: flex;
  flex-wrap: wrap;
}

.panel-section1 {
  order: 1;
}

.panel-section2 {
  order: 2;
}
input {
  margin-top: 5px;
}
/* App.css */
.app-container {
  display: flex;
}

.sidebar {
  width: 250px;
  height: 100vh;
  background-color: #333; /* Adjust the background color as needed */
  color: #fff; /* Adjust the text color as needed */
}

.content-container {
  flex: 1;
  padding: 20px;
}
#responsemessage {
  text-align: center;
  font-size: larger;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-weight: 400;
}
/* Button.css */
.App button {
  background-color: #0d6efd; color: #fff; border: 1px solid #0d6efd; 
  padding: 12px 15px;
  border-radius: 6px; cursor: pointer;
  margin-left: 0px;
}
/*preview section*/

.preview-section {
  border: 2px black;
  width: 100%;
  margin-top: 3rem;
}
.App h1{ margin-bottom: 20px;}

.editSection{ padding: 0 0 0 100px;}
.editSection li{ list-style: none;     padding-bottom: 15px;
  border-bottom: 1px solid #ccc;     margin: 15px 0 0 0; }
.pagename{ margin-right: 10px; float: left;     margin: 5px 0 0 0;
  width: 18%;      font-size: 20px;}
.secbtns {
  display: flex;
}
.btnnew{ background: #0d6efd; padding: 10px 20px; border-radius: 5px; color: #fff; border: none;     margin-left: 10px;}
.editSection a{  background: #0d6efd; text-decoration: none; padding: 7px 20px; border-radius: 5px; color: #fff; border: none;} 
.editSection h1{ margin-bottom: 30px;}
.category { margin: 20px 0;}
.category select{ padding: 8px; }
.category label {
  width: 79px;
  float: left;
  margin-top: 5px;
}
.choosefile input{ font-size: 12px; padding-left: 0px;}
.secbtns{ margin-top: 15px;}
